.btn {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;

  &.btn-square {
    padding: 9px;
    width: 40px;
    height: 40px;
    border-radius: 8px;
    overflow: initial;
    text-overflow: initial;

    .mat-icon {
      min-width: 20px;
      width: 20px;
      height: 20px;
    }
  }

  &:not(.btn-primary, .btn-outline-secondary) {
    &:hover,
    &:active {
      border-color: transparent !important;
    }
  }

  &.btn-primary {
    color: var(--white);

    &:hover,
    &:active {
      color: var(--white) !important;
    }
  }

  &.btn-outline {
    &-primary {
      color: var(--bs-gray-600);
      border-color: var(--bs-gray-300);

      &:hover,
      &:active {
        color: var(--white) !important;
      }
    }

    &-secondary {
      color: var(--bs-gray-600);
      border-color: var(--bs-gray-300);

      &:hover,
      &:active {
        background-color: var(--bs-secondary);
        color: var(--white) !important;
      }
    }
  }

  &.btn-icon {
    padding: 0;
    width: 24px;
    height: 24px;
    border: none;
    border-radius: 0;

    & > .mat-icon {
      width: inherit;
      height: inherit;
    }
  }
}

.form-label {
  margin-bottom: 4px;
  color: var(--bs-gray-600);
  font: {
    size: 12px;
    weight: 400;
  };
  line-height: 19px;
}

.form-control,
.form-select {
  font-size: 14px;
  line-height: 22px;

  &:disabled {
    color: var(--bs-gray-600);
  }
}

.nav.nav-pills {
  margin-bottom: 32px;
  padding: 2px;
  background-color: var(--card-surface);
  border-radius: 24px;

  @media (min-width: 768px) {
    display: inline-flex;
  }

  @media (max-width: 767px) {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow: auto;
    white-space: nowrap;

    .nav-item {
      flex: 1;
      white-space: nowrap;
    }
  }

  .nav-item {
    border-radius: 24px;

    .nav-link {
      width: 100%;
      color: var(--bs-gray-600);
      font: {
        size: 14px;
        weight: 500;
      }
      line-height: 22px;
      text-align: center;
      border: 1px solid var(--card-surface);
      border-radius: 24px;
      transition: .2s ease-in-out;

      &:hover {
        background-color: #ffffffa6;
      }

      &.active {
        background-color: var(--white);
        color: var(--bs-gray-900);
        border-color: #0000001a;
      }

      & + .nav-link {
        margin-left: 2px;
      }
    }
  }
}

.dropdown-menu {
  padding: 0;
  border: none;
  border-radius: 16px;
  box-shadow: 0 -8px 8px -4px #00000008, 0 -20px 24px -4px #00000008, 0 8px 8px -4px #00000008, 0 20px 24px -4px #00000014;
  overflow: hidden;

  & > a,
  & > button {
    padding: 16px;

    .icon {
      min-width: 24px;
      width: 24px;
      height: 24px;
      color: var(--bs-primary);
    }

    .label {
      font: {
        size: 14px;
        weight: 500;
      }
      line-height: 22px;
    }

    &:active {
      .icon {
        color: inherit;
      }
    }
  }
}

.accordion-item {
  background: transparent;
  border: 1px solid var(--bs-gray-300);
  border-left: none;
  border-right: none;
  border-radius: 0 !important;

  .accordion-button {
    padding: 16px 0;
    background: transparent;
    color: var(--bs-gray-900) !important;
    font: {
      size: 14px;
      weight: 500;
    }
    line-height: 22px;
    border-radius: 0 !important;
    box-shadow: none !important;
    outline: none !important;

    &:after {
      background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTYgMTJIMTgiIHN0cm9rZT0iIzI5MkQzMiIgc3Ryb2tlLXdpZHRoPSIxLjUiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8cGF0aCBkPSJNMTIgMThWNiIgc3Ryb2tlPSIjMjkyRDMyIiBzdHJva2Utd2lkdGg9IjEuNSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo=');
    }

    &[aria-expanded="true"] {
      &:after {
        background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTYgMTJIMTgiIHN0cm9rZT0iIzI5MkQzMiIgc3Ryb2tlLXdpZHRoPSIxLjUiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8L3N2Zz4K');
      }
    }
  }

  .accordion-body {
    padding: 0 0 16px;
    color: var(--bs-gray-600);
    font: {
      size: 14px;
      weight: 400;
    }
    line-height: 22px;
  }
}

.modal-content {
  border-radius: 24px;
}

.modal-header {
  border-bottom: none;
}

.modal-footer {
  border-top: none;
}


.form-check-label {
  color: var(--bs-gray-600);
  font: {
    size: 14px;
    weight: 400;
  }
  line-height: 22px;
}
