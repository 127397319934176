h1 {
  margin-bottom: 16px;
  font: {
    size: 32px;
    weight: 700;
  }
  line-height: 1.2;
}

h2 {
  margin-bottom: 16px;
  font: {
    size: 24px;
    weight: 600;
  }
  line-height: 1.2;
}

h3 {
  margin-bottom: 16px;
  font: {
    size: 20px;
    weight: 500;
  }
  line-height: 1.2;
}

h4 {
  margin-bottom: 16px;
  font: {
    size: 18px;
    weight: 500;
  }
  line-height: 1.2;
}

a {
  text-decoration: none;
}

ol {
  counter-reset: item;

  li {
    display: block;

    &:before {
      content: counters(item, ".") ". ";
      counter-increment: item;
    }
  }
}



section {
  margin-bottom: 56px;

  @media (max-width: 991px) {
    margin-bottom: 32px;
  }
}

//p {
//  margin-bottom: 16px;
//  font: {
//    size: 14px;
//    weight: 400;
//  }
//  line-height: 1.6;
//}

img {
  max-width: 100%;
  height: auto;
}

.sidenav-menu {
  &.modal {
    transition: none !important;

    .modal-dialog {
      transform: translate(-50px, 0) !important;
    }

    &.show {
      .modal-dialog {
        transform: translate(0, 0) !important;
      }
    }

    .modal-content {
      width: 80vw;
      max-width: 300px;
    }
  }
}

.page-container {
  padding-top: 32px;
  padding-bottom: 32px;

  h1 {
    margin-bottom: 32px;

    @media (max-width: 767px) {
      margin-bottom: 24px;
    }
  }
}

.card-grid {
  margin-bottom: calc(var(--bs-gutter-x) * -1);
}

.lb-outerContainer {
  max-width: 100%;
  overflow: auto;
}

.btn-telegram,
.btn-viber {
  padding: 8px 24px;
  height: 40px;
  color: var(--white);
  font: {
    size: 14px;
    weight: 600;
  }
  line-height: 22px;
  border: none;
  border-radius: 20px;
  transition: .2s ease-in-out;

  .icon {
    min-width: 24px;
    width: 24px;
    height: 24px;
  }

  &:focus,
  &:hover {
    color: var(--white) !important;
    box-shadow: 0 8px 8px -4px #00000008, 0 20px 24px -4px #00000014;
  }
}

.btn-telegram {
  background-color: #29a8e9 !important;

  &:focus,
  &:hover {
    background-color: #29a8e9 !important;
  }
}

.btn-viber {
  background-color: #7360f2 !important;

  &:focus,
  &:hover {
    background-color: #7360f2 !important;
  }
}
